body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  max-width: none !important; /* Remove the max-width limitation */
  width: 100%; /* Extend the container to full width */
  padding-right: 15px; /* Keep right padding for consistency */
  padding-left: 15px; /* Keep left padding for consistency */
}

.summary-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 10px;
  max-height: 250px;
}

.summary-box {
  text-align: center;
  margin-left: 8px;
  margin-right: 8px;
}


.summary-title {
  font-size: 14px;
  margin-bottom: 5px;
}

.summary-number {
  font-size: 22px;
  font-weight: bold;
}


.title-bar {
  display: flex;
  margin-top: 20px;
  background-color: rgb(80, 80, 80);
  color: white;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  background-color: rgb(164, 164, 164);
  color:white;
}

.filter-item {
  display: flex;
}

.quick-filter-box {
  display: flex;
  justify-content: space-around;
  background-color: rgb(164, 164, 164);
  color:white;
}


.right-al {
  text-align: right;
}

.adj-price-cell {
  background-color: beige !important;
  position: relative;
  cursor: pointer;
}

.rc-cell {
  background-color: rgb(250, 255, 253) !important;
}

.left-border {
  border-left: 1px solid black;
}

.adj-price-cell::after {
  content: "Price adjusted (partially or fully) using 27Jun2023 close price!";
  position: absolute;
  white-space: nowrap;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
  z-index: 100;
}

.adj-price-cell:hover::after {
  visibility: visible;
  opacity: 1;
}


.full-height {
  height: 100vh; /* Full viewport height */
}