/* Portfolio.css */
.numeric-cell {
    text-align: right;
  }
  
.bold-cell {
    font-weight: bold;
  }

.green-cell {
    color: green !important;
    text-align: right;
  }
  
.red-cell {
    color: red !important;
    text-align: right;
  }

  .small-text {
    font-size: 12px;
  }
